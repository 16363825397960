import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { contractAbiP } from '../slider/web3/abi';
import {
    useWeb3ModalAccount,
    useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { ethers } from "ethers";
import './style.css';

const BlastPointsBanner = () => {
    const rpcUrl = "https://rpc.blast.io";
    const provider = new ethers.JsonRpcProvider(rpcUrl);
    const { walletProvider } = useWeb3ModalProvider();

    const { address, isConnected } = useWeb3ModalAccount();

    const [blastPoints, setBlastPoints] = useState(null);
    const [isSet, setIsSet] = useState(false);

    const [totalSupply, setTotalSupply] = useState("0");
    

    const [balance, setBalance] = useState("0");
    const [pBalance, setPBalance] = useState("0");
    const apiUrl = 'https://blastdoge-api.onrender.com/apis/check-point-balances';
    const secretToken = '7a978d91e4e15b9f715c824ae8479a2a17e2de8fb796cba2b4964540a60bd9d164a453548e42a36ce76cb0e2318bf71b191ddd631355a6bc4373a2feef6283dc';
    const backgroundImageUrl = require('../../assets/images/background/paw.png'); // Adjust this path

    const bannerStyle = {
        position: 'absolute',
        padding: '10px',
        borderRadius: '15px',
        background: `url(${backgroundImageUrl}) no-repeat center center`,
        backgroundSize: 'cover', 
        color: 'yellow',
        zIndex: '9',
        top: '100px',
        left: '17.5px',
    };

    useEffect(() => {
        async function fetchUserBalance() {
            if (!isConnected || !address || !walletProvider) return;
            const contractAddressP = "0x5b5711F3D7Df8E697D3BfeC965be42a3dEc51f23";
            const contract = new ethers.Contract(contractAddressP, contractAbiP, provider);

            try {
                const balance = await contract.balanceOf(address);
                const formattedBalance = ethers.formatEther(balance.toString());
                const roundedBalance = parseFloat(formattedBalance).toFixed(2);

                setPBalance(roundedBalance);
            } catch (error) {
            }
        }

        fetchUserBalance();
    }, [address, isConnected, walletProvider]);

    useEffect(() => {
        async function fetchTotalSupply() {
            if (!isConnected || !address || !walletProvider) return;
            const contractAddressP = "0x5b5711F3D7Df8E697D3BfeC965be42a3dEc51f23";
            const contract = new ethers.Contract(contractAddressP, contractAbiP, provider);

            try {
                const balance = await contract.totalSupply();
                const formattedBalance = ethers.formatEther(balance.toString());
                const roundedBalance = parseFloat(formattedBalance).toFixed(2);
                setTotalSupply(roundedBalance);
            } catch (error) {
                console.error("Error fetching total supply:", error);
                setTotalSupply("Error");
            }
        }

        fetchTotalSupply();
    }, [address, isConnected, walletProvider]);

    useEffect(() => {
        async function fetchBlastPoints() {
            try {
                const response = await axios.get(apiUrl, {
                    headers: {
                        'x-api-secret': secretToken,
                    },
                });
                setBlastPoints(response.data);
                if(pBalance > 0) {
                    const userShare = calculateUserShare(response.data);
                    setBalance(userShare)
                    setIsSet(true)
                }
                
            } catch (error) {
                console.error('Error fetching blast points:', error.message);
            }
        }

        function calculateUserShare(totalPoints) {
            if (!isConnected || pBalance <= 0 || totalSupply <= 0) {
                return 'Not available';
            }
            const userBalancePercentage = (parseFloat(pBalance) / parseFloat(totalSupply)) * 100;
            const userShare = (parseFloat(totalPoints) * userBalancePercentage) / 100;
            return userShare.toFixed(2);
        }

        fetchBlastPoints();

        const intervalId = setInterval(() => {
            fetchBlastPoints();
        }, 0.25 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [isConnected, pBalance, totalSupply]);



    return (
        <>
            {!isConnected && (
                <div style={{ ...bannerStyle }}>
                    <p style={{ margin: 0 }}>Blast Points: {blastPoints ? parseFloat(blastPoints).toFixed(2) : 'Loading...'}</p>
                </div>
            )}
            {isConnected && (
                <div style={{ ...bannerStyle, top: '95px', right: '15px', left: 'auto' }}>
                    <p style={{ margin: 0 }}>Blast Points: {blastPoints ? parseFloat(blastPoints).toFixed(2) : 'Loading...'}</p>
                    {isSet && pBalance > 0 && totalSupply > 0 && balance && (
                        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ margin: 0 }}>Your Portion: {blastPoints ? parseFloat(balance).toFixed(2) : 'Loading...'}</p>
                        </div>
                    )}
                    {!isSet && pBalance <= 0 && (
                        <div style={{ marginTop: '12px', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ margin: 0 }}>Hold pBDOGE to share points</p>
                        </div>
                    )}
                </div>
            )}
        </>
    );
    
};

export default BlastPointsBanner;
