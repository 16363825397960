import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    useWeb3Modal,
    useWeb3ModalAccount,
    useWeb3ModalProvider
} from "@web3modal/ethers/react";
import { ethers } from "ethers";
import { contractAbi } from '../web3/abiClaim';
import { contractAbiP } from "../web3/abi";
import Confetti from "react-confetti";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { Web3Provider } from '@ethersproject/providers';

import './styles.scss';

SliderItem.propTypes = {
    item: PropTypes.object,
};


function SliderItem(props) {
    const rpcUrl = "https://rpc.blast.io";
    const provider = new ethers.JsonRpcProvider(rpcUrl);

    const contractAddress = "0x24a0cA2ca7940540f1C8994C92B22877b9ba899d";
    const contractAddressP = "0x5b5711F3D7Df8E697D3BfeC965be42a3dEc51f23";

    const [totalSupply, setTotalSupply] = useState(0);

    const { open } = useWeb3Modal();
    const [quantity, setQuantity] = useState(1);
    const [mintProgresss, setmintProgresss] = useState(0);

    const [loading, setLoading] = useState(false);
    const [txHash, setTxHash] = useState('');
    const [showConfetti, setShowConfetti] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [claimSuccess, setClaimSuccess] = useState(false);
    const [openT, setOpenT] = useState(false);
    const { chainId, address, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { item } = props;
    const maxSupply = 5101;
    let displayText = "pBDOGE minted";

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;


    let mintPrice = 0.0025;

    const contractInterface = new ethers.Interface(contractAbi);
    const data = contractInterface.encodeFunctionData("publicClaim");

    useEffect(() => {
        const contract = new ethers.Contract(contractAddressP, contractAbiP, provider);
        async function fetchTotalSupply() {
            try {
                const balance = await contract.totalSupply();
                const formattedBalance = ethers.formatEther(balance.toString());
                const roundedBalance = parseFloat(formattedBalance).toFixed(2);
                setTotalSupply(parseInt(roundedBalance));
            } catch (error) {
                console.error("Error fetching total supply:", error);
                setTotalSupply(0);
            }
        }

        fetchTotalSupply();

        const intervalId = setInterval(fetchTotalSupply, 65000);

        if (totalSupply >= 2101) {
            mintPrice = 0.005;
        }

        const mintProgress = ((maxSupply - totalSupply) / maxSupply) * 100;

        const mintProgressRounded = Math.round(mintProgress);
        setmintProgresss(mintProgressRounded);

        return () => clearInterval(intervalId);
    }, []);


    const mintToken = async () => {
        if (!isConnected || !walletProvider) {
            alert("Please connect your wallet first");
            return;
        }

        setLoading(true);
        const targetChainId = "0x13E31"; // 13E31

        if (chainId !== parseInt(targetChainId, 16)) {
            try {
                await walletProvider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: targetChainId }], // Requires hexadecimal chainId
                });
                // Chain switched successfully, you can proceed with the transaction after this
            } catch (error) {
                const switchError = error;
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902) {
                    try {
                        await walletProvider.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainId: targetChainId,
                                    chainName: "Blast", // Example name, replace with actual
                                    nativeCurrency: {
                                        symbol: "ETH", // Example symbol, replace with actual
                                        decimals: 18,
                                    },
                                    rpcUrls: ["https://rpc.blast.io"],
                                    blockExplorerUrls: ["https://blastscan.io"],
                                },
                            ],
                        });
                        // The network has been added successfully.
                    } catch (addError) {
                        console.error("Error adding new chain", addError);
                    }
                }
                console.error("Error switching chain", switchError);
                return;
            }
        }



        const provider = new Web3Provider(walletProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractAbi, signer);

        let p = ethers.parseUnits("0.0001", "gwei");
        try {

            const txResponse = await contract.publicClaim({
                value: ethers.parseEther((mintPrice * quantity).toString()),
                gasPrice: ethers.toBeHex(p),
                gasLimit: ethers.toBeHex('20000000')
            });

            const txReceipt = await waitForTransaction(provider, txResponse.hash);

            setShowConfetti(true);
            setTxHash(txReceipt.transactionHash);
            setOpenT(true);
            setClaimSuccess(true);

        } catch (error) {
            console.error('Error minting token:', error);
            setErrorMessage(error.message || "Error claiming token");
        } finally {
            setLoading(false);
        }
    };

    async function waitForTransaction(provider, txHash, pollInterval = 5000) {
        let txReceipt = null;
        while (txReceipt === null) {
            txReceipt = await provider.getTransactionReceipt(txHash);
            if (txReceipt === null) {
                // Transaction is not yet mined
                await new Promise(resolve => setTimeout(resolve, pollInterval));
            }
        }
        return txReceipt;
    }

    // Decrement function with prevention of going below 1
    const handleDecrement = () => {
        setQuantity(prevQuantity => Math.max(1, prevQuantity - 1));
    };

    // Increment function
    const handleIncrement = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };

    return (
        <div className={`box-slider ${item.classAction}`} id='hero'>
            {showConfetti && (
                <Confetti
                    className='conf'
                    width={window.innerWidth}
                    height={window.innerHeight}
                    recycle={false}
                />
            )}
            <img className='bg-slider' src={item.bgImg} alt="bdoge" />
            <div className="box-slider__main">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-box">
                                <h1 className="title">{item.title}</h1>
                                <p className="sub-title">{item.desc}</p>
                                <div className="live-indicator">
                                    <div className="green-dot"></div>
                                    <span>Pre-BDOGE mint is closed.</span>
                                </div>
                                <div className="flex justify-end prog test">
                                    <div className="flex justify-between">
                                        <div className="mx-auto">
                                            <p className="text-blast-100">
                                                {totalSupply} pBDOGE of {maxSupply} minted
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {!isConnected && (<>
                                    <div className="wrap-btn">
                                        <button onClick={() => open()} disabled={loading} className="tf-button-st2 btn-effect"><span className="effect">connect wallet</span></button>
                                    </div>
                                    <a href='https://www.synapseprotocol.com/?fromChainId=42161&toChainId=81457' target='_blank' className='bridge'>Bridge to Blast L2</a>
                                </>
                                )}

                                {isConnected && (<>
                                    {/* <div className="wrap-mint">
                                        <button onClick={() => mintToken()} className="tf-button-st2 btn-effect mintP">
                                            <span className="effect">
                                               mint pre-BDOGE
                                            </span>
                                        </button>
                                        <div className="number-input-container">
                                            <button className="decrement" onClick={(e) => { e.preventDefault(); handleDecrement() }}>-</button>
                                            <input
                                                className="input-field"
                                                type="number"
                                                value={quantity}
                                                onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value)))}
                                                min={1}
                                                disabled={true}
                                            />
                                            <button className="increment" onClick={(e) => { e.preventDefault(); handleIncrement() }}>+</button>
                                        </div>
                                    </div> */}
                                    <a href='https://www.synapseprotocol.com/?fromChainId=42161&toChainId=81457' target='_blank' className='bridge'>Bridge to Blast L2</a>

                                    {errorMessage && (
                                        <div className="text-red">{errorMessage}</div>
                                    )}
                                    {/* <div className="text ">
                                        < h2 className="text-camo-300 text-xl">Details</h2>
                                        <ul className="info-list">
                                            <li>No max per wallet</li>
                                            <li>Min: 1 pre-BDOGE</li>
                                            <li>First 2101 pre-BDOGE: 0.0025 ETH</li>
                                            <li>Rest at: 0.005 ETH</li>
                                        </ul>
                                    </div> */}
                                    <Dialog
                                        open={openT}
                                        onClose={() => setOpenT(false)}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Transaction Successful"}</DialogTitle>
                                        <DialogContent>
                                            <p id="alert-dialog-description">
                                                Transaction successful.
                                                <a href={`https://blastscan.io/tx/${txHash}`} target="_blank" rel="noopener noreferrer">View on Blastscan</a>
                                            </p>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => setOpenT(false)} autoFocus>
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </>)}
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-12">
                            <div className="image">
                                <img src={item.img} alt="bdoge" width={'585px'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SliderItem;