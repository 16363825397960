import React, { useState, useEffect } from 'react';
import './style.css'; // Ensure this file exists and is correctly imported

const Leaderboard = () => {
  const [holdersData, setHoldersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const secretToken = '7a978d91e4e15b9f715c824ae8479a2a17e2de8fb796cba2b4964540a60bd9d164a453548e42a36ce76cb0e2318bf71b191ddd631355a6bc4373a2feef6283dc';
  // State to hold the current date and time
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const formatDateTime = (date) => {
    // Get UTC components of the date
    const utcYear = date.getUTCFullYear().toString().slice(-2); // Last two digits of year
    const utcMonth = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Month as a two-digit number
    const utcDate = ('0' + date.getUTCDate()).slice(-2); // Date as a two-digit number
    const utcHour = date.getUTCHours();
    const utcMinutes = ('0' + date.getUTCMinutes()).slice(-2); // Minutes as a two-digit number

    // Convert 24-hour time to 12-hour format with AM/PM
    const hour = utcHour % 12 === 0 ? 12 : utcHour % 12;
    const amPm = utcHour >= 12 ? 'PM' : 'AM';
  
    return `${utcDate}/${utcMonth}/${utcYear} - ${hour}:${utcMinutes} ${amPm} UTC`;
  };
  

  const fetchLeaderboardData = async () => {
    try {
      const response = await fetch('https://blastdoge-api.onrender.com/apis/update-leaderboard/', {
        method: 'GET', 
        headers: {
          'Content-Type': 'application/json',
          'x-api-secret': secretToken, 
        },
      });
  
      if (response.ok) {
        const newData = await response.json();
  
        if (newData && newData.length > 0) {
          setHoldersData(newData);
        }
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };

  useEffect(() => {
    const fetchDataAndUpdateTime = () => {
      fetchLeaderboardData(); 
      setCurrentDateTime(new Date()); 
    };

    fetchDataAndUpdateTime(); 
    const interval = setInterval(fetchDataAndUpdateTime, 60000); 

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  const itemsPerPage = 10;
  const totalPages = Math.ceil(holdersData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = holdersData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="terminal-container leaderboard">
      <h2>Blast Points Leaderboard</h2>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Address</th>
            <th>pBDOGE Balance<sup>*</sup></th>
            <th>Earned Blast Points</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((holder, index) => (
            <tr key={index}>
              <td>{indexOfFirstItem + index + 1}</td>
              <td>{`${holder.HolderAddress.substring(0, 4)}...${holder.HolderAddress.substring(holder.HolderAddress.length - 4)}`}</td>
              <td>{holder.Balance}</td>
              <td>{holder.BlastPointShare !== null ? holder.BlastPointShare.toFixed(2) : '0.00'}</td>
              </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {[...Array(totalPages).keys()].map(number => (
          <button key={number} onClick={() => paginate(number + 1)}>{number + 1}</button>
        ))}
      </div>
      <div className="snapshot-date"><sup className='date'>*</sup>Latest Update: {formatDateTime(currentDateTime)}</div>
      <div className="snapshot-date">Distribution Date: 24/06/24 - 2:06 PM UTC </div>
    </div>
  );
};

export default Leaderboard;
