import React, { useState, useEffect } from 'react';
import './style.css'
import { FaArrowRight } from 'react-icons/fa';
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import axios from 'axios';
import { contractAbiP } from '../slider/web3/abi';
import { ethers } from "ethers";


function TerminalComponent() {
    const rpcUrl = "https://rpc.blast.io";
    const provider = new ethers.JsonRpcProvider(rpcUrl);
    const { address, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const [inputValue, setInputValue] = useState('');
    const [result, setResult] = useState('');
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [error, setError] = useState('');
    const [typingIndex, setTypingIndex] = useState(0);
    const [isInputEnabled, setIsInputEnabled] = useState(false);
    const [pBalance, setPBalance] = useState(0);

    const isValidAddress = (address) => {
        return /^0x[a-fA-F0-9]{40}$/.test(address);
    };

    useEffect(() => {
        if (isConnected && address) {
            setInputValue(address);
        }

        const welcomeText = "Enter your Blast wallet address...";
        const typingEffectInterval = setInterval(() => {
            if (typingIndex < welcomeText.length) {
                setWelcomeMessage(welcomeText.slice(0, typingIndex + 1));
                setTypingIndex(typingIndex + 1);
            } else {
                setIsInputEnabled(true);
                clearInterval(typingEffectInterval);
            }
        }, 100);

        return () => clearInterval(typingEffectInterval);
    }, [typingIndex, address, isConnected]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        if (error) setError(''); // Clear the error when the user starts typing
    };

    async function fetchNFTBalance() {
        if (!isConnected || !address || !provider) return;
        const contractAddressP = "0x5b5711F3D7Df8E697D3BfeC965be42a3dEc51f23";
        const contract = new ethers.Contract(contractAddressP, contractAbiP, provider);

        try {
            const balance = await contract.balanceOf(address);
            // Convert balance to string and then format it to display only 4 decimal places
            const formattedBalance = ethers.formatEther(balance.toString());
            const roundedBalance = parseFloat(formattedBalance).toFixed(2);
            setPBalance(roundedBalance);
            return roundedBalance;
        } catch (error) {
            console.error("Error fetching NFT balance:", error);
            setPBalance("Error");
        }
    }

    
    useEffect(() => {
        fetchNFTBalance();
    }, [address, isConnected, walletProvider]);

    const handleFormSubmit = () => {
        if (inputValue.trim() === '' || !isValidAddress(inputValue)) {
            setError("Please enter a valid EVM wallet address.");
            return;
        }
        
        let secretToken= '7a978d91e4e15b9f715c824ae8479a2a17e2de8fb796cba2b4964540a60bd9d164a453548e42a36ce76cb0e2318bf71b191ddd631355a6bc4373a2feef6283dc'
        axios.post('https://blastdoge-api.onrender.com/apis/check-wallet', { address: inputValue }, {
            headers: {
                'x-api-secret': secretToken
            }
        })
        .then(async response => {
            if (response.data != null && response.data > 0) {
                let balance = response.data;              
                await fetchNFTBalance().then((res)=>{
                    if(parseFloat(res) > 0){
                        const t = balance+parseFloat(res)
                        setResult(`You can claim ${t} BDOGE! Stay tuned 🎉`);
                    } else {
                        setResult(`You can claim ${balance} BDOGE! Stay tuned 🎉`);
                    }
                });
            } else {
                if(pBalance>0){
                    setResult(`You can claim ${pBalance} BDOGE! Stay tuned 🎉`);
                } else {
                    setResult('You are not eligible for any claim at the moment.');
                }
            }
        })
        .catch(error => {
            setResult('Error occurred while checking eligibility.');
        });
    };

    return (
        <div className="terminal-container" id='terminal'>
            <div className="col-md-12">
                <div className="tf-title mb-58">
                    <p className="h8 sub-title">AIRDROP</p>
                    <h4 className="title">Verify Your Eligibility Now</h4>
                </div>
                <div className="terminal-input-container">
                    <input
                        className="terminal-input"
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={welcomeMessage}
                        disabled={!isInputEnabled}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                handleFormSubmit();
                            }
                        }}
                    />
                    <FaArrowRight className="submit-icon" onClick={handleFormSubmit} />
                </div>
                {error && <div style={{ color: 'red' }}>{error}</div>}
                {result && <div className="terminal-output">{result}</div>}
            </div>
        </div>
    );
}

export default TerminalComponent;
