import React , {useState} from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import Button from '../../components/button';
import AboutItem from './about-item';

About.propTypes = {
    data : PropTypes.array,
};

function About(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subtitle : 'About us',
            title: 'What is Blast Doge?',
            desc : "As a pioneering project on Blast L2, Blast Doge taps into the power of ERC404 by Pandora Labs, offering a total supply of 20,404 BDOGE. Holders gain access to innovative features such as gas fee revenue sharing, our advanced staking platform, native yield rewards, and the flexibility to trade BDOGE as either ERC20 or ERC721 assets. Additionally, active engagement enables holders to earn Blast Points for exclusive rewards. For more details on our mission and progress, explore our website and join our Discord server."
        }
    )

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const sectionTop = section.getBoundingClientRect().top;
            const offsetTop = sectionTop + window.pageYOffset - 25;
    
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        }
    };

    return (
        <section className="tf-section tf-about" id="about">
            <div className="container" id="about">
                <div className="row">
                    <div className="col-xl-5 col-md-12">
                        <div className="content-about mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title st2">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                            <p>{dataBlock.desc}</p>
                                <Button title="get BDOGE" onClick={() => scrollToSection('hero')} />
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            {
                                data.map(item => (
                                    <AboutItem key={item.id} item={item} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;