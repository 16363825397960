import React from 'react';

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "1daaef4edcc75d6e92c94632eeb2b755";

// 2. Set chains
const mainnet = {
    chainId: 81457,
    name: 'Blast',
    currency: 'ETH',
    explorerUrl: 'https://blastscan.io',
    rpcUrl: 'https://rpc.blast.io'
};

// 3. Create modal
const metadata = {
    name: 'Blast Doge',
    description: 'The first ERC404 token on Blast L2! Powered by native yield.',
    url: 'https://blast-doge.xyz/',
    icons: ['https://avatars.mywebsite.com/']
};

const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    themeVariables: {
        '--w3m-color-mix': '#FFFFFF',
        '--w3m-color-mix-strength': 20,
        '--w3m-font-family': 'geom-graphic',
        '--w3m-accent': '#FCFC03',
        "--w3m-font-size-master" : "12px",
    },
    themeMode: 'dark'
});

export function Web3ModalProvider({ children }) {
    return children;
}
