const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Connect your wallet',
        text: 'Connect your EVM-compatible wallet (Metamask recommended) to the Blast Doge website and be sure to select the Blast network.',
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        title: 'Enter mint amount',
        text: 'Enter the amount of pre-BDOGE tokens you wish to mint. The minimum mint amount is 1 pre-BDOGE (pBDOGE), which can later be exchanged at a 1:1 ratio with BDOGE once claiming opens.'
    },
    {
        id: 3,
        numb: '03',
        title: "Complete transaction",
        text: 'Once the transaction is completed, the corresponding amount of pBDOGE will be minted to your wallet.'
    },
    {
        id: 4,
        numb: '04',
        title: "Join holder discord channel",
        text: "Join the Blast Doge Discord to stay informed. After the presale, we'll distribute Blast Points and gas fee revenue to pBDOGE holders. Hold more pBDOGE to earn more rewards & Blast Points!"    },
];

export default dataWork;
