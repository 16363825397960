import HomeOne from "./HomeOne";
import ComingSoon from "./ComingSoon";
import FAQ from "./FAQ";
import Page404 from "./404";

const routes = [
  { path: '/', component: <HomeOne />},
  { path: '/coming-soon', component: <ComingSoon />},
  { path: '/faq', component: <FAQ />},
  { path: '/404', component: <Page404 />},
]

export default routes;