import img1 from '../images/logo/Thruster.png';
import img2 from '../images/logo/alpha.jpg';
import img3 from '../images/logo/blasto.jpg';
import img4 from '../images/logo/deri.jpg';
import img5 from '../images/logo/eco.jpg';
import img6 from '../images/logo/hyper.jpg';
import img7 from '../images/logo/rogue.jpg';
import img8 from '../images/logo/pac.png';
import img9 from '../images/logo/wasabi.jpg';

const dataPartner = [
    {
        img: img1,
        link: "https://twitter.com/ThrusterFi",
    },
    {
        img: img2,
        link: 'https://twitter.com/BlastAlpha_',
    },
    {
        img: img3,
        link: 'https://twitter.com/Blastopedia',
    },
    {
        img: img4,
        link: 'https://twitter.com/deriblast',
    },
    {
        img: img5,
        link: 'https://twitter.com/BlastEcosystem_',
    },
    {
        img: img6,
        link: 'https://twitter.com/HyperBlastDex',
    },
    {
        img: img7,
        link: 'https://twitter.com/Roguex_io',
    },
    {
        img: img8,
        link: 'https://twitter.com/Pac404Official',
    },
    {
        img: img9,
        link: 'https://twitter.com/wasabi_protocol',
    },
];

export default dataPartner;
