import img1 from '../images/common/785.png'
import img2 from '../images/common/1066.png'
import img3 from '../images/common/7598.png'
import img4 from '../images/common/8533.png'
import img5 from '../images/common/8708.png'
import img6 from '../images/common/17089.png'
import img7 from '../images/common/9140.png'
import img8 from '../images/common/19153.png'
import img9 from '../images/common/8738.png'
import img10 from '../images/common/17118.png'
import img11 from '../images/common/17625.png'
import img12 from '../images/common/17335.png'
import img13 from '../images/common/17959.png'
import img14 from '../images/common/5625.png'
import img15 from '../images/common/5391.png'
import img16 from '../images/common/12261.png'
import img17 from '../images/common/12260.png'
import img18 from '../images/common/9855.png'
import img19 from '../images/common/10070.png'
import img20 from '../images/common/10176.png'
import img21 from '../images/common/19336.png'
import img22 from '../images/common/20234.png'
import img23 from '../images/common/20400.png'
import img24 from '../images/common/19907.png'


const dataProject = [
    {
        id: 1,
        title: 'BDOGE #785',
        img: img1,
    },
    {
        id: 2,
        title: 'BDOGE #1066',
        img: img2,
    },
    {
        id: 3,
        title: 'BDOGE #7598',
        img: img3,
    },
    {
        id: 4,
        title: 'BDOGE #8533',
        img: img4,
    },
    {
        id: 5,
        title: 'BDOGE #8708',
        img: img5,
    },
    {
        id: 6,
        title: 'BDOGE #17089',
        img: img6,
    },
    {
        id: 7,
        title: 'BDOGE #9140',
        img: img7,
    },
    {
        id: 8,
        title: 'BDOGE #19153',
        img: img8,
    },
    {
        id: 9,
        title: 'BDOGE #8738',
        img: img9,
    },
    {
        id: 10,
        title: 'BDOGE #17118',
        img: img10,
    },
    {
        id: 11,
        title: 'BDOGE #17625',
        img: img11,
    },
    {
        id: 12,
        title: 'BDOGE #17335',
        img: img12,
    },
    {
        id: 13,
        title: 'BDOGE #17959',
        img: img13,
    },
    {
        id: 14,
        title: 'BDOGE #5625',
        img: img14,
    },
    {
        id: 15,
        title: 'BDOGE #5391',
        img: img15,
    },
    {
        id: 16,
        title: 'BDOGE #12261',
        img: img16,
    },
    {
        id: 17,
        title: 'BDOGE #12260',
        img: img17,
    },
    {
        id: 18,
        title: 'BDOGE #9855',
        img: img18,
    },
    {
        id: 19,
        title: 'BDOGE #10070',
        img: img19,
    },
    {
        id: 20,
        title: 'BDOGE #10176',
        img: img20,
    },
    {
        id: 21,
        title: 'BDOGE #19336',
        img: img21,
    },
    {
        id: 22,
        title: 'BDOGE #20234',
        img: img22,
    },
    {
        id: 23,
        title: 'BDOGE #20400',
        img: img23,
    },
    {
        id: 24,
        title: 'BDOGE #19907',
        img: img24,
    },
]

export default dataProject;