import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import './App.scss'
import ScrollToTop from "./ScrollToTop";
import { Web3ModalProvider } from "./Web3Modal";
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <BrowserRouter>
            <ScrollToTop />
            <Web3ModalProvider>
                <App />
                <Analytics />
                <SpeedInsights />
            </Web3ModalProvider>
        </BrowserRouter>
    </React.Fragment>
);

