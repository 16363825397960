const menus = [
    {
        id: 1,
        name: 'Home',
        links: 'hero',
    },
    {
        id: 2,
        name: 'Airdrop',
        links: 'terminal',
    },
    {
        id: 3,
        name: 'Road Map',
        links: 'page-roadmap',
    },
    {
        id: 4,
        name: 'Staking',
        links: '/coming-soon',
    },
    {
        id: 5,
        name: 'My BDOGE',
        links: '/coming-soon',
    },    
]

export default menus;