import bgImg1 from '../images/background/hero.png';
import img1 from '../images/common/doge.png';

const dataSlider = [
    {
        id: 1,
        title: 'Blast Doge',
        desc : 'The first ERC404 token on Blast L2! Powered by native yield. Trade, hold, stake, and win! 🪙',
        bgImg: bgImg1,
        img : img1
    }
]

export default dataSlider;