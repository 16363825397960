const dataFaq = [
    {
        id: 1,
        title: 'What is Blast Doge?',
        content: 'Blast Doge is the first ERC404 meme token built on the Blast L2 network, leveraging native yield and gas fees revenue to reward holders. We initiated our testnet contract deployment on February 21, 2024, with the aim of pioneering innovation and utility within the Blast ecosystem.',
        show: 'show'
    },
    {
        id: 2,
        title: "What's Blast Doge tokenomics?",
        content: 'Blast Doge tokenomics breakdown: 25% LP, 25% Presale, 25% Airdrop, 10% Staking, 10% Marketing/Collab, 5% Development.',
    },    
    {
        id: 3,
        title: 'What are the benefits of holding Blast Doge tokens?',
        content: 'Holding Blast Doge tokens offers numerous benefits, including native yield on the Blast L2 platform, upcoming staking opportunities, rewards from collaborations, the establishment of a DAO, access to unique artwork, and the ability to trade tokens as ERC20 or ERC721 assets.',
    },
    {
        id: 4,
        title: 'How do I participate in the presale?',
        content: 'To participate in the presale, simply connect your compatible wallet and follow the instructions to mint pre-BDOGE tokens.',
    },
    {
        id: 5,
        title: 'What is the Blast Doge DAO?',
        content: 'The Blast Doge DAO (Decentralized Autonomous Organization) is a community-governed entity designed to make collective decisions regarding the future development and direction of the Blast Doge ecosystem.',
    },
    {
        id: 6,
        title: 'Can I stake Blast Doge tokens?',
        content: 'Yes, staking opportunities for Blast Doge tokens will be available soon. Stay tuned for updates on how you can participate and earn rewards by staking your BDOGE.',
    }
];

export default dataFaq;
