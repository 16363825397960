const dataRoadmap = [
    {
        time: 'Q1 2024',
        list: [
            {
                text: 'ERC404 experimentation'
            },
            {
                text: 'Testnet launch and minting'
            },
            {
                text: 'Community building initiatives'
            },
            {
                text: 'Presale and project launch preparations'
            },
            {
                text: 'Blast Doge Launch/Reveal & airdrop'
            }
        ],
        position: 'left'
    },
    {
        time: 'Q2 2024',
        list: [
            {
                text: 'Staking platform development'
            },
            {
                text: 'New website launch'
            },
            {
                text: 'Collaborations and partnerships'
            },
            {
                text: 'Rewards token implementation'
            },
        ],
        position: 'right'
    },
    {
        time: 'Q3 2024',
        list: [
            {
                text: 'Expansion of ecosystem utilities'
            },
            {
                text: 'Enhancements to staking features'
            },
            {
                text: 'Integration of new reward mechanisms'
            },
            {
                text: 'Continued community engagement'
            },
        ],
        position: 'left'
    },
    {
        time: 'Q4 2024',
        list: [
            {
                text: 'Further development of gas fess sharing'
            },
            {
                text: 'Introduction of advanced reward systems'
            },
            {
                text: 'Exploration of additional collaborations'
            },
            {
                text: 'Continued growth on other chains (SatoshiVM, Polygon, BSC..)'
            },
        ],
        position: 'right'
    },
    {
        time: 'Q1 2025',
        list: [
            {
                text: 'Optimization of platform functionalities'
            },
            {
                text: 'Expansion into new markets'
            },
            {
                text: 'Innovation in reward mechanisms'
            },
            {
                text: 'Focus on sustainability and longevity'
            },
        ],
        position: 'left'
    }
];

export default dataRoadmap;
