const dataAbout = [
    {
        id: 1,
        title: 'Unique, 1/1 generated',
        desc: 'Each Blast Doge NFT is uniquely generated, making it a one-of-a-kind digital asset.'
    },
    {
        id: 2,
        title: '110+ traits in 8 categories',
        desc: 'With over 110+ traits spread across 8 categories, every Blast Doge NFT showcases a diverse and rich set of attributes.'
    },
    {
        id: 3,
        title: 'Usage rights included',
        desc: 'Purchasing a Blast Doge NFT grants you full rights to use and display the artwork as you wish.'
    },
]

export default dataAbout;
