import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import menus from '../../pages/menu';
import './styles.scss';
import logo from '../../assets/images/logo/logo.png';
import logoBlast from '../../assets/images/logo/blast_logo.svg';
import logoETH from '../../assets/images/logo/eth_logo.svg';
import logoBDOGE from '../../assets/images/logo/blastt.png';
import { contractAbiP } from '../slider/web3/abi';
import {
    useWeb3ModalAccount,
    useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { ethers } from "ethers";
import Button from '../button';

const rpcUrl = "https://rpc.blast.io";
const provider = new ethers.JsonRpcProvider(rpcUrl);

const Header = () => {
    const { walletProvider } = useWeb3ModalProvider();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { chainId, address, isConnected } = useWeb3ModalAccount();
    const truncatedAddress = address ? address.slice(0, 4) + ".." + address.slice(-4) : undefined;
    const [balance, setBalance] = useState("0");
    const [pBalance, setPBalance] = useState("0");
    const tokenDetails = {
        address: '0x5b5711F3D7Df8E697D3BfeC965be42a3dEc51f23',
        symbol: 'pBDOGE',
        decimals: 18,
        image: logoBDOGE,
    };


    useEffect(() => {
        async function fetchBalance() {
            if (walletProvider && address) {
                const balanceBigInt = await walletProvider.request({
                    method: "eth_getBalance",
                    params: [address],
                });
                const balanceInEth = ethers.formatEther(balanceBigInt);
                const roundedBalance = parseFloat(balanceInEth).toFixed(4);
                setBalance(roundedBalance);
            }
        }

        if (isConnected) {
            fetchBalance();
        }
    }, [walletProvider, address, isConnected, chainId]);



    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const sectionTop = section.getBoundingClientRect().top;
            const offsetTop = sectionTop + window.pageYOffset - 25;

            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        }
    };


    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        async function fetchNFTBalance() {
            if (!isConnected || !address || !walletProvider) return;
            const contractAddressP = "0x5b5711F3D7Df8E697D3BfeC965be42a3dEc51f23";
            const contract = new ethers.Contract(contractAddressP, contractAbiP, provider);

            try {
                const balance = await contract.balanceOf(address);
                // Convert balance to string and then format it to display only 4 decimal places
                const formattedBalance = ethers.formatEther(balance.toString());
                const roundedBalance = parseFloat(formattedBalance).toFixed(2);
                setPBalance(roundedBalance);
            } catch (error) {
                console.error("Error fetching NFT balance:", error);
                setPBalance("Error");
            }
        }

        fetchNFTBalance();
    }, [address, isConnected, walletProvider]);

    const openLinkInNewWindow = () => {
        window.open("https://discord.gg/PSF38QGUDz", '_blank');
    };

    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY > 300);
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/about') {
            const aboutSection = document.getElementById('about-section');
            if (aboutSection) {
                aboutSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const [menuActive, setMenuActive] = useState(false);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };

    const addTokenToMetaMask = async () => {
        try {
            if (window.ethereum && window.ethereum.isMetaMask) {
                const wasAdded = await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: tokenDetails.address,
                            symbol: tokenDetails.symbol,
                            decimals: tokenDetails.decimals,
                            image: tokenDetails.image,
                        },
                    },
                });

                if (wasAdded) {
                    console.log('Token added to MetaMask successfully!');
                } else {
                    console.log('Token addition to MetaMask was cancelled by the user.');
                }
            } else {
                alert('MetaMask is not installed. Please install it to use this feature.');
            }
        } catch (error) {
            console.error('Error adding token to MetaMask:', error);
        }
    };


    const isShowButton = windowWidth < 1020 || windowWidth > 1450;

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="header__logo">
                        <NavLink to="/"><img src={logo} alt="Blast Doge" /></NavLink>
                    </div>
                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} >
                        <ul id="menu-primary-menu" className="menu">
                            {menus.map((data, idx) => (
                                <li key={idx} onClick={() => handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}>
                                    <Link onClick={() => scrollToSection(data.links)}>{data.name}</Link>
                                    {data.namesub &&
                                        <ul className="sub-menu">
                                            {data.namesub.map((submenu) => (
                                                <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
                                            ))}
                                        </ul>
                                    }

                                </li>

                            ))}
                            {isConnected && (<>
                                {
                                    truncatedAddress !== undefined && (
                                        <li key="address" className={`menu-item logop ${menuActive ? 'active' : ''}`}>
                                            <div className="blast-m">
                                                <img alt="blast logo" src={logoBlast} width={35} height={35} />
                                                <p className="text"><strong>{truncatedAddress}</strong></p>
                                            </div>
                                        </li>
                                    )
                                }
                                <li key="eth" className={`menu-item logop ${menuActive ? 'active' : ''}`}><div className="eth-m">
                                    <img alt="eth logo" src={logoETH} width={17} height={17} />
                                    <p className="text"><strong>{balance}</strong></p>
                                </div></li>
                                <li key="pbdoge" className={`bdoge-l menu-item logop ${menuActive ? 'active' : ''}`} onClick={addTokenToMetaMask}>
                                    <div className="bdoge-m">
                                        <img alt="bdoge logo" src={logoBDOGE} width={26} height={26} />
                                        <p className="text"><strong>{pBalance}</strong></p>
                                    </div>
                                </li>

                            </>)}
                        </ul>
                    </nav>
                    {isShowButton && (
                        <Button className='discord menu' title='join discord' path='' onClick={openLinkInNewWindow} />
                    )}
                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                </div>
            </div>
            {isConnected && (<>
                <div className={`wrapper ${menuActive ? 'active' : ''}`}>
                    {truncatedAddress !== undefined && (
                        <div className="blast">
                            <img alt="blast logo" src={logoBlast} width={35} height={35} />
                            <p className="text">{truncatedAddress}</p>
                        </div>
                    )}
                    <div className="eth">
                        <img alt="eth logo" src={logoETH} width={17} height={17} />
                        <p className="text">{balance}</p>
                    </div>
                    <div className="bdoge" onClick={addTokenToMetaMask}>
                        <img alt="bdoge logo" src={logoBDOGE} width={26} height={26} />
                        <p className="text">{pBalance}</p>
                    </div>
                </div>
            </>)}
        </header>
    );

}

export default Header;
